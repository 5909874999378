<template>
  <section class="usersView">
    <SlidebarUsers class="usersView__slidebar" />
    <div class="usersView__userSelected">
      <router-view />
    </div>
  </section>
</template>

<script>
  import SlidebarUsers from "./SlidebarUsers.vue";
  import { socket } from "@/socket";
  export default {
    components: { SlidebarUsers },
    mounted() {
      socket?.on("userEdited", async (payload) => {
        this.$store.commit("user/updateUser", payload);
      });
    },
  };
</script>

<style lang="scss">
  .usersView {
    height: 100%;
    max-width: 100%;
    width: 1440px;
    &__slidebar {
      max-width: 520px;
    }
    &__userSelected {
      @include Flex(row);
      width: 100%;
    }
    @media (min-width: 640px) {
      @include Flex(row, flex-start, flex-start);
      &__slidebar {
        height: calc(100vh - 30px);
      }
      &__userSelected {
        overflow: auto;
      }
    }
    @media (min-width: 1440px) {
      &__slidebar {
        height: calc(100vh - 160px);
      }
    }
    @media (min-width: 1440px) {
      padding-top: 50px;
      padding-left: 85px;
    }
  }
  .content.users {
    padding: 0;
  }
  .main-page.menu- {
    padding: 0;
  }
  .main-page.menu-closed {
    padding: 0;
  }
</style>
